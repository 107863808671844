
@import "../../bootstrap/variables.less";

@body-bg:					#fff;

@text-color:				#000;
@link-color:				#000;
@link-hover-color:			#f00;
@link-hover-decoration:		underline;

@font-family-base:			Arial, Helvetica, sans-serif;
@font-size-base:			14px;
@line-height-base:			1.25;

@font-size-h1:				25px;
@headings-small-color:		#fff;

@thumb-width:				242px;
@thumb-height:				182px;
@thumb-margin-top:			2px;
@thumb-margin-right:		3px;
@thumb-margin-bottom:		5px;
@thumb-margin-left:			3px;
@thumb-border-width:		1px;
@thumb-border:				1px solid #000;
@thumb-hover-border:		1px dashed #ff0000;

@zindex-popup:				100;
@tooltip-arrow-width:		5px;
@tooltip-bg:				#444;
@popup-text-color:			#fff;
@popup-font-weight:			bold;

@social-links-margin:		5px 0;

@sites-list-font-size:				12px;
@sites-list-color:					#000;
@sites-list-title-margin-bottom:	5px;
@sites-list-title-size:				20px;
@sites-list-title-weight:			bold;
@sites-list-title-decoration:		none;

@import "common/bootstrap.less";
@import "../../common/utils.less";
@import "../../common/flags-small.less";
@import "../../common/flags.less";
@import "common/modules.less";
@import "common/sites_list.less";
@import "common/popup.less";
@import "common/mozaique.less";

a {
	text-decoration: underline;
	cursor: pointer;
}
.header {
	overflow: hidden;
	width: 90%;
	margin: 0 auto 10px;
}
h1 {
	font-weight: 500;
	text-align: center;
	width: 50%;
	float: left;
	line-height: 20px;
	margin: 10px 0 0;
	.slogan {
		font-size: 16px;
		color: #f00;
		font-style: italic;
		font-weight: bold;
	}
}
#social-links {
	margin: 5px;
	width: 270px;
	overflow: hidden;
	float: right;
	clear: none;
}
#language-switcher {
	position: static;
	float: right;
	margin: 0 10px;
}
.thumb-block {
	p {
		font-size: 12px;
		line-height: 15px;
		padding: 1px 0;
		&.metadata {
			padding-top: 4px;
			font-style: italic;
			strong {
				color: #f00;
				font-style: normal;
			}
		}
	}
}
.footer {
	font-size: 14px;
	margin: 30px 0 5px;
	a {
		text-decoration: underline;
	}
}